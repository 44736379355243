import { Injectable, signal } from '@angular/core';

import { NavbarStyle, NavbarStyleEnum, UiColorsNavbar } from '@yuno/api/interface';

export interface MobileNavbarColors {
	background: string | undefined;
	font: string | undefined;
}

export interface MainUiDisplay {
	display: string;
	url: string;
}

@Injectable({
	providedIn: 'root'
})
export class MainUiService {
	routeLevels = signal<number>(1);

	display = signal<MainUiDisplay>({ display: 'Home', url: 'apps' });
	appView = signal<boolean>(false);

	navbarType = signal<NavbarStyle>(NavbarStyleEnum.default);
	navbarColors = signal<MobileNavbarColors>({
		background: undefined,
		font: undefined
	});

	containerPosition = signal<'relative' | 'absolute'>('relative');

	setRouteLevels(val: number): void {
		this.routeLevels.set(val);
	}

	setDisplaySignal(val: MainUiDisplay): void {
		this.display.set(val);
	}

	setAppView(val: boolean): void {
		this.appView.set(val);
	}

	setNavbarColors(val?: UiColorsNavbar): void {
		this.navbarColors.set({
			background: val ? val.background : undefined,
			font: val ? val.font : undefined
		});
	}

	setNavbarType(val?: NavbarStyle): void {
		this.navbarType.set(val || NavbarStyleEnum.default);
	}

	setContainerPosition(val: 'relative' | 'absolute'): void {
		this.containerPosition.set(val);
	}
}
