import { InjectionToken } from '@angular/core';
import { GLOBAL_ENV } from '_environments/types';

export interface ENV_TYPE extends GLOBAL_ENV {
	baseUrl: string;

	// only in development
	'yuno-client-name'?: string;
}

export const ENVIRONMENT = new InjectionToken<ENV_TYPE>('environment');
export const CLIENT_SUB_DOMAIN = new InjectionToken<string>('client_sub_domain');
