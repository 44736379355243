import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';

import { MainUiService } from '../services';

export const routeLevelsResolver: ResolveFn<string> = (route): Observable<string> => {
	const ui = inject(MainUiService);
	const levels = route.data['routeLevels'] || 1;

	ui.setRouteLevels(levels);

	return levels;
};
