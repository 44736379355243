import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { MainUiService } from '../services';

export const uiContainerResolver: ResolveFn<void> = (route): void => {
	const service = inject(MainUiService);

	let position = route.data['containerPosition'];

	if (!position && (position !== 'relative' || position !== 'absolute')) {
		position = 'relative';
	}

	service.setContainerPosition(position);
};
