import { Injectable, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { EventData } from '@maplibre/ngx-maplibre-gl';
import {
	LngLatBoundsLike,
	LngLatLike,
	Map,
	MapLibreEvent,
	MapOptions,
	StyleSpecification
} from 'maplibre-gl';
import { Observable, take } from 'rxjs';

import { ApiObservableService } from '@yuno/angular/api';
import { MessageService } from '@yuno/angular/notifications';

@Injectable({
	providedIn: 'root'
})
export class YunoMapService {
	private readonly api = inject(ApiObservableService);
	private readonly router = inject(Router);
	private readonly defaultMapStyle = '5e42a2df5e53be02b5f37b00';
	private readonly message = inject(MessageService);

	$mapStyle = signal<StyleSpecification | undefined>(undefined);
	$mapStyleBounds = signal<LngLatBoundsLike | undefined>(undefined);
	$map = signal<Map | undefined>(undefined);

	$queryZoom = signal<number>(10);
	$queryCenter = signal<MapOptions['center'] | undefined>({ lat: 0, lng: 0 });
	$queryPitch = signal<number>(0);
	$queryBearing = signal<number>(0);

	private get(styleUrl: string): Observable<StyleSpecification> {
		return this.api.get<StyleSpecification>(styleUrl).pipe(take(1));
	}

	/**
	 * Get the map style, based on appId and themeId
	 * Or defaults to a generic style
	 * @param appId
	 * @param themeId
	 */
	getMapStyle(appId: string, themeId?: string): void {
		let styleUrl = `mapstyle/${this.defaultMapStyle}/app/${appId}/style.json?intern=true`;

		if (themeId) {
			styleUrl = `theme/${appId}/${themeId}/style.json?intern=true`;
		}

		this.get(styleUrl).subscribe(style => {
			this.$mapStyle.set(style);

			const bounds = (style.metadata as any)['yuno:theme:bounds'];
			if (bounds) {
				this.$mapStyleBounds.set(bounds);
			}
		});
	}

	/**
	 * Reset the map
	 * removes it from DOM and clears the service
	 */
	reset(): void {
		this.$map.set(undefined);
		this.$mapStyle.set(undefined);
		this.$mapStyleBounds.set(undefined);
	}

	/**
	 * Save a reference to the Map in this service
	 * @param map
	 */
	setMap(map: Map): void {
		this.$map.set(map);
	}

	/**
	 * Set the center of the map
	 * @param coords
	 */
	setCenter(coords: LngLatLike): void {
		const map = this.$map();
		map?.setCenter(coords);
	}

	/**
	 * Remove the map from the service
	 */
	removeMap(): void {
		this.$map.set(undefined);
	}

	/**
	 *  Update the router with coordinates
	 */
	setMapUrl(
		event: MapLibreEvent<MouseEvent | TouchEvent | WheelEvent | undefined> & EventData
	): void {
		const center = event.target.getCenter();
		center.lat = parseFloat(center.lat.toFixed(5));
		center.lng = parseFloat(center.lng.toFixed(5));
		const zoom = parseFloat(event.target.getZoom().toFixed(4));
		const pitch = parseFloat(event.target.getPitch().toFixed(4));
		const bearing = parseFloat(event.target.getBearing().toFixed(4));

		this.router.navigate([], {
			replaceUrl: true,
			queryParams: {
				map: `${center.lat},${center.lng},${zoom},${bearing},${pitch}`
			},
			queryParamsHandling: 'merge'
		});
	}

	/**
	 * Parse the map url
	 * @param query
	 */
	parseMapUrl(query?: string):
		| {
				lat: number;
				lng: number;
				zoom: number;
				bearing: number;
				pitch: number;
		  }
		| undefined {
		if (!query) {
			return undefined;
		}

		const [lat, lng, zoom, bearing, pitch] = query.split(',').map(parseFloat);

		this.$queryZoom.set(zoom);
		this.$queryCenter.set({ lat, lng });
		this.$queryPitch.set(pitch);
		this.$queryBearing.set(bearing);

		return { lat, lng, zoom, bearing, pitch };
	}

	/**
	 * Get the users current location
	 */
	getCurrentPosition(): Observable<GeolocationPosition> {
		return new Observable(observer => {
			const opts = { enableHighAccuracy: true };
			navigator.geolocation.getCurrentPosition(
				(position: GeolocationPosition) => {
					observer.next(position);
					observer.complete();
				},
				(error: GeolocationPositionError) => {
					observer.error(error);
				},
				opts
			);
		});
	}

	/**
	 * Error handling for user location
	 * @param error
	 */
	userLocationError(error: GeolocationPositionError): void {
		if (error.code === 1) {
			this.message.sendToast('Location access has been denied', 'error', 5);
		}

		if (error.code === 2) {
			this.message.sendToast('Location is not available at the moment', 'error', 5);
		}

		if (error.code === 3) {
			this.message.sendToast('Location request timed out', 'error', 5);
		}
	}
}
