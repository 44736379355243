import { Injectable, inject } from '@angular/core';
import { Observable, take } from 'rxjs';

import { ApiObservableService } from '@yuno/angular/api';
import { AppPortalData } from '@yuno/api/interface';

@Injectable({
	providedIn: 'root'
})
export class AppService {
	private readonly api = inject(ApiObservableService);

	getApp(appId: string): Observable<AppPortalData> {
		return this.api.get<AppPortalData>(`app/portal/${appId}`).pipe(take(1));
	}
}
