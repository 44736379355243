import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';

import { MainUiService } from '../services';

export const navbarResolver: ResolveFn<string> = (route): Observable<string> => {
	const ui = inject(MainUiService);
	const display = route.data['navbarTitle'] || 'Home';
	const url = route.data['navbarUrl'] || 'apps';
	ui.setDisplaySignal({
		display,
		url
	});

	return display;
};
