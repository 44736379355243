import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { getState } from '@ngrx/signals';
import { Observable, catchError, filter, of, switchMap, take } from 'rxjs';

import { AppStore } from '@yuno/yuno/features/functions';

export const AppGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
	const store = inject(AppStore);
	const state = getState(store);

	if (!state.data && !state.isLoading) {
		store.getApp(route.params['appId']);
	}

	return toObservable(store.data).pipe(
		// Wait for a status that s not pending validating
		filter(() => !store.isLoading()),
		switchMap(data => {
			if (!data) {
				return of(false);
			}

			return of(true);
		}),
		catchError(() => {
			return of(false);
		}),
		// the resolver needs an completed Observable and the NGRX observable never gets completed
		// take(1) completes it
		take(1)
	);
};
