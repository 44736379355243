import { computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { switchMap, tap } from 'rxjs';

import { AppPortalData } from '@yuno/api/interface';
import { MainUiService } from '@yuno/yuno/core';

import { AppService } from '../services';

type AppState = {
	data: AppPortalData | null;
	isLoading: boolean;
};

const initialState: AppState = {
	data: null,
	isLoading: false
};

export const AppStore = signalStore(
	{ providedIn: 'root' },
	withState(initialState),
	withComputed(({ data }) => ({
		appId: computed(() => data()?._id),
		appUrl: computed(() => data()?.appUrl),
		appUrlIntern: computed(() => data()?.appUrl + '/intern'),
		appName: computed(() => data()?.appName),
		language: computed(() => data()?.config?.language)
	})),
	withMethods((state, appService = inject(AppService), uiService = inject(MainUiService)) => ({
		reset: () => patchState(state, initialState),
		getApp: rxMethod<string>(appId =>
			appId.pipe(
				tap(() => patchState(state, { isLoading: true })),
				switchMap(appId =>
					appService.getApp(appId).pipe(
						tapResponse({
							next: data => {
								uiService.setDisplaySignal({
									display: data.appName || data.id || 'App',
									url: data._id as string
								});
								patchState(state, { data, isLoading: false });
							},
							error: () => {
								uiService.setDisplaySignal({ display: 'Home', url: 'apps' });
								patchState(state, { data: initialState.data, isLoading: false });
							}
						})
					)
				)
			)
		)
	}))
);
