import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

import { CanComponentDeactivate, MainUiService } from '../../index';

export const ResetRouteLevel: CanDeactivateFn<CanComponentDeactivate> = (): boolean => {
	const service = inject(MainUiService);
	service.setRouteLevels(1);
	return true;
};
