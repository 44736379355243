import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { MainUiService } from '@yuno/yuno/core';

export const appViewResolver: ResolveFn<boolean> = (route): boolean => {
	const ui = inject(MainUiService);

	// The firstChild grabs the viewer data from child routes
	const bool = route.data['viewer'] || route.firstChild?.data['viewer'] || false;
	ui.setAppView(bool);

	return bool;
};
