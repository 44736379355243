import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';

import { AuthFacade } from '@yuno/angular-auth';

export const ClientRoute = 'clients';
export const InternalRoute = 'internal';

export const RedirectGuard: CanActivateFn = (): Observable<UrlTree> => {
	const authFacade = inject(AuthFacade);
	const router = inject(Router);

	return authFacade.user$.pipe(
		map(user => {
			if (user?.role === 'appuser') {
				return router.createUrlTree([ClientRoute]);
			}

			return router.createUrlTree([InternalRoute]);
		})
	);
};
